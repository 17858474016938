/*
 * @Author: N0ts
 * @Date: 2023-04-26 11:55:45
 * @Description: router config
 * @FilePath: /web/src/router/router.ts
 * @Mail：mail@n0ts.cn
 */

import {
    createRouter,
    createWebHashHistory,
    RouteRecordRaw,
    createWebHistory
} from "vue-router";

const homePage = () => import("../pages/homePage.vue");
const onePage = () => import("../pages/onePage.vue");
const twoPage = () => import("../pages/twoPage.vue");
const adminPage = () => import("../pages/adminPage.vue");

const routes = [
    {
        path: "/",
        component: homePage,
        meta: { title: "加载中" }
    },
    { path: "/one", component: onePage, meta: { title: "查询结果" } },
    { path: "/two", component: twoPage, meta: { title: "证书防伪查询" } },
    { path: "/admin", component: adminPage, meta: { title: "后台管理" } }
];

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title as string;
    next();
});

export default router;
